@tailwind base;
@tailwind components;
@tailwind utilities;

.gradiente {
    -webkit-mask-image: linear-gradient(100deg, transparent, transparent, white, white, white, white);
}

.icone{
    width: 16px;
}
.icone:hover{
    transform: scale(1.5);
}
.balao {
    /* layout */
	position: absolute;
    z-index: 99;
    top: 50%;
    left: 0;

	/* looks */
	background-color: #fff;
	border-radius: 0.75rem;
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    transition: opacity 2s ease;
}

.balao::before {
	/* layout */
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 95%;
    /* offset should move with padding of parent */
	left: 1.5em;
	border: .75rem solid transparent;
	border-bottom: none;

	/* looks */
	border-top-color: #fff;
	filter: drop-shadow(0 0.125rem 0.0625rem rgba(0, 0, 0, .1));
}

.back a img {
    transition: all 0.3s;
    animation: seta-animation 0.3s ease-in-out alternate infinite;
    width: 1.5rem;
}

@keyframes seta-animation {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-15%);
    }
}
